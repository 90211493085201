import { useMsal } from '@azure/msal-react';
import { styled } from '@mui/material';
import { loginRequest } from 'authConfig';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useLocation } from 'react-router-dom';

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const StoreLink = () => {
    const detectDevices = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            window.open('https://play.google.com/store/apps/details?id=com.yrf.castingapp', '_self');
            return "Android";
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.open('https://apps.apple.com/in/app/yrf-casting/id6463196462', '_self');
            return "iOS";
        }
    }

    useEffect(() => {
        detectDevices();
    }, []);

    return (
        <DocumentTitle title={'Store Link'}>
            <>
            </>
        </DocumentTitle >

    )
}

export default StoreLink;