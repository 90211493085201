import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import React, { } from 'react';
import DocumentTitle from 'react-document-title';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';

const HomePage = () => {
    const { instance, inProgress } = useMsal();
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname;

    if (path == "/web") {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    } else if (path.includes('/users/UserDetails/')) {
        window.sessionStorage.setItem('urlData', JSON.stringify(window.location.href))
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    }

    return (
        <DocumentTitle title={'Home Page'}>
            <>
                <Helmet>
                    <title>YRF Casting</title>
                    <link rel="stylesheet" type="text/css" href="css/styles.css" />
                </Helmet>

                <div>
                    <section id="logo-home">
                        <img src="images/yrf-casting-logo.png" />
                    </section>
                    <section>&nbsp;&nbsp;</section>
                    <section id="app-icons">
                        {/* <h2>Coming Soon!</h2> */}
                        <a href="https://apps.apple.com/in/app/yrf-casting/id6463196462">
                            <img src="images/apple-store.png" alt="App 1 Icon" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.yrf.castingapp">
                            <img src="images/google-play-store.png" alt="App 2 Icon" />
                        </a>
                    </section>
                    <footer style={{
                        backgroundColor: "#333",
                        color: '#fff',
                        textAlign: 'center',
                        padding: '10px',
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        display: 'flex'
                    }}>
                        <div style={{ flex: 0.8, justifyContent: 'flex-end', display: 'flex' }}>
                            <p>&copy; Yash Raj Films Pvt. Ltd.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href='' onClick={() => navigate("/privacypolicy")}>Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href='' onClick={() => navigate("/termsofservice")}>Terms & Conditions</a></p>
                        </div>
                        <div style={{ flex: 0.5, textAlign: 'center', justifyContent: 'flex-end', display: 'flex' }}>
                            <p style={{ paddingRight: 20 }}>
                                Powered by IFI Techsolutions
                            </p>
                        </div>
                    </footer>
                </div>
            </>
        </DocumentTitle >

    )
}

export default HomePage;