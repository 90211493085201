
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { VscError } from 'react-icons/vsc'
import { RiErrorWarningLine } from 'react-icons/ri'

const ServerErrorModal = ({ handleCloseAlert, openAlert, popUpFor, btnText, contentText }) => {

    return (
        <Dialog fullWidth={true}
            maxWidth={'xs'}
            aria-labelledby="max-width-dialog-title"
            open={openAlert}
            onClose={handleCloseAlert}>
            {popUpFor === 'Server Error' || popUpFor === 'Already Exist Audition' ?
                <DialogTitle style={{ justifyContent: 'center', display: 'flex' }}><VscError size={70} color={'#F27777'} /></DialogTitle>
                :
                <DialogTitle style={{ justifyContent: 'center', display: 'flex' }}><RiErrorWarningLine size={70} color={'#F27777'} /></DialogTitle>

            }
            <DialogContent style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <DialogContentText style={{ fontWeight: 'bold' }}>
                    {popUpFor === 'Already Exist' || popUpFor === 'Already Exist Audition' || popUpFor === 'Already Exist User' ? 'Server Error' : popUpFor}
                </DialogContentText>
                {popUpFor === 'Server Error' ?
                    <DialogContentText>
                        Something went wrong, please try again later.
                    </DialogContentText> :
                    popUpFor === 'No Internet' ?
                        <DialogContentText>
                            Please check your internet connection.
                        </DialogContentText> :
                        popUpFor === 'Already Exist Audition' ?
                            <DialogContentText>
                                Audition title already exist
                            </DialogContentText>
                            :
                            popUpFor === 'Already Exist User' ?
                                <DialogContentText>
                                    User already exist
                                </DialogContentText>
                                :
                                popUpFor === 'Record Not Found' ?
                                    <DialogContentText>
                                        Your details does not match
                                    </DialogContentText>
                                    :
                                    <DialogContentText>
                                        Project name already exist
                                    </DialogContentText>
                }

            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
                <Button sx={{ ":hover": { borderColor: "#ea232a" }, borderColor: '#F06C6A', color: '#F06C6A' }} onClick={handleCloseAlert} style={{ width: '20%' }}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ServerErrorModal;