import { useEffect, useReducer } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { b2cPolicies } from "../../authConfig";
import { AuthProvider } from "../contexts/JWTAuthContext";
import { EventType } from "@azure/msal-browser";
import axios from "axios.js";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import HomePage from "app/views/singlepage/HomePage";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const PageLayout = (props) => {
  const location = useLocation();
  const path = location.pathname;
  const { instance, inProgress } = useMsal();
  const [state, dispatch] = useReducer(reducer, initialState);


  useEffect(() => {
    const callbackId = instance.addEventCallback(async (event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        let activeAccount = instance.getActiveAccount();

        if (activeAccount) {
          try {
            let idTokenClaims = activeAccount.idTokenClaims;
            let encodedString = window.btoa(JSON.stringify(idTokenClaims));
            setSession(encodedString);
          } catch (error) {
            console.log("Error ==> ", error);
          }
        }

        if (
          event.payload.idTokenClaims["tfp"] === b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                account.idTokenClaims["tfp"] === b2cPolicies.names.signUpSignIn
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && event.error.errorMessage.includes("AADB2C90118")) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
      if (event.eventType === EventType.LOGOUT_SUCCESS) {
        // debugger
        // instance.loginRedirect(loginRequest);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  return (
    <>

      <UnauthenticatedTemplate>
        {(path == "/privacypolicy" || path == "/termsofservice" || path == "/deleteaccount" || path == '/storelink') ? (
          <></>
        )
          :
          (
            <>
              <HomePage />
            </>
          )}
        {/* <Button
          variant="info"
          onClick={handleLoginRedirect}
          className="profileButton"
        >
          Login
        </Button> */}
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <AuthProvider>{props.children}</AuthProvider>
      </AuthenticatedTemplate>
    </>
  );
};
