
import { catchError } from "app/firebase/Crashlytics";
import { BASE_URL } from "./apiList";
import axios from "axios";
import { containerFileName, sasToken, storageAccountName } from "authConfig";

export const getAPIPayload = (method, bodyData) => {
    if (method == 'GET') {
        let payloadObj = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }
        return payloadObj;
    } else {
        let payloadObj = {
            method: method,
            body: JSON.stringify(bodyData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
        return payloadObj;
    }
}

export const CallAPI = (route, method, bodyData) => {
    try {
        console.log("Final API URL ==> ", route)
        console.log("Final Body ==> ", JSON.stringify(bodyData))
        let payloadObj = getAPIPayload(method, bodyData);

        return fetch(BASE_URL + route, payloadObj)
            .then((response) => response.json())
            .then((json) => {
                return json;
            })
            .catch((error) => {
                console.log("Error in callAPI ==> ", error);
                return 'error';
            });
    } catch (error) {
        catchError(error, 'callAPI_1');
        return error
    }
}

export const CallAPIUploadPic = (base, bodyData) => new Promise((resolve, reject) => {
    fetch(BASE_URL + base, {
        method: 'POST',
        body: bodyData,
        headers: {
            // Accept: "*/*",
            Accept: 'application/json',
            // "Content-Type": "multipart/form-data",
            // 'Content-Type': 'application/octet-stream',
            // 'Authorization': 'Bearer ' + global.AccessToken,
            // "Access-Control-Allow-Origin": 'https://talenthunt-dev.azurewebsites.net',
            // 'Cache-Control': 'no-cache'
            // 'Ocp-Apim-Subscription-Key': '8363c2478ab24490a90da70f6f2ae24a'
        }
    }).then((response) => {
        var promise = new Promise(function (resolve) {
            resolve(response.json());
        });
        Promise.all([response, promise]).then((values) => {
            if (values[0].status == 401) {
            }
            else if (values[0].status == 200) {
                resolve(values[1]);
            }
            else if (values[0].status == 204) {
                resolve('error');
            }
            else if (values[0].status == 500) {
                resolve(values[1]);
            }
            else if (values[0].status == 400) {
                resolve(values[1]);
            } else {
                resolve(values)
            }
        })
    }).catch((error) => {
        catchError(error, false, "CallAPIUploadPic_1")
        resolve('offline');
    })
});

export const CallAPIUploadVideo = (base, bodyData) => new Promise((resolve, reject) => {
    fetch(BASE_URL + base, {
        method: 'POST',
        body: bodyData,
        headers: {
            // Accept: "*/*",
            Accept: 'application/json',
            // "Content-Type": "multipart/form-data",
            // 'Content-Type': 'application/octet-stream',
            // 'Authorization': 'Bearer ' + global.AccessToken,
            // "Access-Control-Allow-Origin": 'https://talenthunt-dev.azurewebsites.net',
            // 'Cache-Control': 'no-cache'
            // 'Ocp-Apim-Subscription-Key': '8363c2478ab24490a90da70f6f2ae24a'
        }
    }).then((response) => {
        var promise = new Promise(function (resolve) {
            resolve(response.json());
        });
        Promise.all([response, promise]).then((values) => {
            if (values[0].status == 401) {
                //         }
                //     })
                // } else {
                //     refreshCount = 0
                // }
            }
            else if (values[0].status == 200) {
                resolve(values[1]);
            }
            else if (values[0].status == 204) {
                resolve('error');
            }
            else if (values[0].status == 500) {
                resolve(values[1]);
            }
            else if (values[0].status == 400) {
                resolve(values[1]);
            } else {
                resolve(values)
            }
        })
    }).catch((error) => {
        catchError(error, false, "CallAPIUploadVideo_1")
        resolve('offline');
    })
});
export const CallAPISasUploadVideo = (base, file, signal) => new Promise((resolve, reject) => {
    let replaceName = file.name.split(' ').join('_');
    fetch(`https://${storageAccountName}.blob.core.windows.net/${containerFileName}/${replaceName}${sasToken}`, {
        method: 'PUT',
        body: file,
        headers: {
            'Content-Type': file.type,
            'x-ms-blob-type': 'BlockBlob',
        },
        signal
    }).then((response) => {
        resolve(response);
    }).catch((error) => {
        catchError(error, false, "CallAPISasUploadVideo_1")
        resolve('offline');
    })
});

export default axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-type": "application/json"
    }
});