
// import { v4 as uuidv4 } from 'uuid';
// export const BASE_URL = "https://dev-casting-app-api.azurewebsites.net/api/";       //  YRF MAIN - DEV
// export const BASE_URL = "https://uat-api.yrfcasting.com/api/";       //  YRF MAIN - UAT
// export const BASE_URL = "https://prod-casting-app-api.azurewebsites.net/api/";       //  YRF MAIN - PROD OLD
export const BASE_URL = "https://api.yrfcasting.com/api/";       //  YRF MAIN - PROD

// export const BASE_STORAGE_URL = "https://stgdevcastingapp.blob.core.windows.net";       // YRF MAIN- DEV
// export const BASE_STORAGE_URL = "https://stguatcastingapp.blob.core.windows.net";       // YRF MAIN- UAT
export const BASE_STORAGE_URL = "https://stgprodcastingapp.blob.core.windows.net";       // YRF MAIN- PROD

export const endPointName = {
    getAllData: "state",
    addEditRequest: "UserData/GetAllUserDataList",
    AllUserDataList: "UserData/AllUserDataList/",
    state: "state/?Type=1&LastUpdatedDate=1900-1-1 00:00:00.000",
    Language: "Language",
    CityData: "City/CityData",
    Country: "country",
    GetWorkExpCategoryMaster: "WorkExpCategory/GetWorkExpCategoryMaster",
    // AddEditPost: "posts/AddEditPost/",
    AddEditPostV2: 'posts/AddEditPostV2',
    SingleUserDetails: "UserData/SingleUserDetails/Userid/",
    // getAllPostsDetails: "Posts/AllPostsDetails",                 //OLD API
    getAllPostsDetails: "Posts/V2/AllPostsDetails",
    authorizeUser: "UserData/Authorize/",
    getPostDetails: "Posts/GetPostDetails",
    getAdminPostAppliedUsersList: "Posts/GetAdminPostAppliedUsersList",
    adminGetPostsAppliedByUserId: "Posts/AdminGetPostsAppliedByUserId",
    // PushNotification: "PushNotification/fcmNotification/V1/send",
    PushNotification: "PushNotification/fcmNotification/V2/send",
    OrganizationMaster: "Organization/GetOrganization/",
    AddPostAppStageStatus: "Posts/AddPostAppStageStatus",
    GetStageHistoryList: "Posts/GetStageHistoryList",
    GetUsersList: "UserData/NewAllUserDataList",
    // SearchUsersList: "UserData/NewAllUserDataList",
    InsertUpdateProjectAndCharcter: "Project/InsertUpdateProjectAndCharcter",
    // AllProjectData: "Project/AllProjectData",
    AllProjectData: "Project/V2/AllProjectData",
    ProjectList: "Project/ProjectList",
    FileUpload: "Image/Upload/",
    AuditionNotification: "PushNotification/AuditionNotification/V1/send",
    // GetDashboardData: "Dashboard/GetDashboardData",          //OLD API
    GetDashboardData: "Dashboard/V2/GetDashboardData",
    // SearchProjectData: "Project/SearchProjectData",          //OLD API
    SearchProjectData: "Project/V2/SearchProjectData",
    // SearchAuditionData: "posts/SearchAuditionData",          //OLD API
    SearchAuditionData: "posts/V2/SearchAuditionData",
    TagsInsertion: "TagMaster/TagsInsertion",
    TagsUpdate: "TagMaster/TagsUpdate",
    GetTagsMaster: "TagMaster/GetTagsMaster",
    VideoUpload: "Video/Upload/InsertUploadedVideo",
    VideoDelete: "Video/Delete",
    Authorize: "UserData/Authorize/",
    GetVideoIdByUserIdAndPostId: "Video/GetVideoIdByUserIdAndPostId",
    UpdateAuditionVideo: "Video/UpdateAuditionVideo",
    GetAdminPostAppliedUsersListVideoURLs: "Posts/GetAdminPostAppliedUsersListVideoURLs",
    // UpdateAdminUser: "AdminUser/UpdateAdminUser",                    //OLD API
    // UpdateAdminUser: "AdminUser/UpdateAdminUserV2",                //OLD API
    UpdateAdminUser: "AdminUser/V3/UpdateAdminUser",
    // GetAdminUserDetails: "AdminUser/GetAdminUserDetails",            //OLD API
    // GetAdminUserDetailsV2: "AdminUser/GetAdminUserDetailsV2",        //OLD API
    GetAdminUserDetails: "AdminUser/V3/GetAdminUserDetails",
    NewAllUserDataList: "UserData/NewAllUserDataList",
    AllTypesUserDataList: "UserData/AllTypesUserDataList",
    InsertCharcterwiseFees: "project/InsertCharcterwiseFees",
    AllCharcterwiseFeesHistory: "project/AllCharcterwiseFeesHistory",
    InsertAdminRole: "AdminRole/InsertAdminRole",
    GetUserRoles: "AdminRole/GetUserRoles",
    // InsertAdminUser: "AdminUser/InsertAdminUser",            //OLD API
    // InsertAdminUserV2: "AdminUser/InsertAdminUserV2",        //OLD API
    InsertAdminUser: "AdminUser/V3/InsertAdminUser",
    GetAllAdminMaster: "AdminUser/GetAllAdminMaster",
    UpdateUserVerification: "UserData/UpdateUserVerification",
    GetProjectCategories: "ProjectCategory/GetProjectCategories",
    GetAllFeedbacks: "Feedback/GetAllFeedbacks",
    InsertSliderImage: "SliderImage/InsertSliderImage",
    GetEducationTrainingDetails: "UserEducation/GetEducationTrainingDetails",
    PermanentDeleteActorDataFromWeb: "UserData/PermanentDeleteActorDataFromWeb",
    DeactivateActorData: "UserData/DeactivateActorData",
    ActivateActor: "UserData/ActivateActor",
    GetActivateDeactivateActorHistory: "UserData/GetActivateDeactivateActorHistory",
    ComplexionMaster: "TagMaster/ComplexionMaster",
    Qualification: 'Qualification',
    InsertActorDataFromWebPanel: "userdata/InsertActorDataFromWebPanel",
    // DeactivateAdminUser: 'AdminUser/DeactivateAdminUser',        //OLD API
    ActivateDeactivateAdminUser: 'AdminUser/ActiveDeactivateAdminUser',
    DeleteAdminUser: 'AdminUser/DeleteAdminUser',
    SendAuditionCreationNotification: "PushNotification/SendAuditionCreationNotification/V1/send",
    UpdateAdminUserPassword: "AdminUser/UpdateAdminUserPassword",
    GetFeedbacksByUserId: "Feedback/GetFeedbacksByUserId",
    UpdateActorProfileReadUnread: "UserData/UpdateActorProfileReadUnread",
    GetAllSliderImages: 'SliderImage/GetAllSliderImages',
    UpdateSliderImage: "SliderImage/UpdateSliderImage",
    AddRemarksforAuditionAgainstActor: "Posts/AddRemarksforAuditionAgainstActor",
    GetRemarksHistoryAgainstActor: "Posts/GetRemarksHistoryAgainstActor"
    // VideoStoragBlob: `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blobName}${sasToken}`
}