import { useMsal } from '@azure/msal-react';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Stack, styled, Typography, useMediaQuery, Button, TextField, IconButton, Divider, DialogActions, CircularProgress } from '@mui/material';
import { loginRequest } from 'authConfig';
import React, { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from '../material-kit/dialog/ConfirmationDialog';
import { Span } from 'app/components/Typography';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ServerErrorModal from 'app/components/ServerErrorModal';
import { catchError } from 'app/firebase/Crashlytics';
import { CallAPI } from 'apis/callAPI';
import { endPointName } from 'apis/apiList';
import useAuth from 'app/hooks/useAuth';
import { CustomSnackBar } from 'app/components';
import { FcApproval } from 'react-icons/fc';
import Swal from 'sweetalert2';


const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const DeleteAccountPage = () => {
    const navigate = useNavigate();
    const { logout, user } = useAuth();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [openPopUp, setOpenPopUp] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [emailId, setEmailId] = useState('');
    const [isFirstNameError, setIsFirstNameError] = useState(false);
    const [isLastNameError, setIsLastNameError] = useState(false);
    const [isMobileNoError, setIsMobileNoError] = useState(false);
    const [isEmailIdError, setIsEmailIdError] = useState(false);
    const [APILoading, setAPILoading] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isOnline, setOnline] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");

    // let user = JSON.parse(sessionStorage.getItem("userJSON"));

    const onSubmit = () => {
        try {
            if (isOnline) {
                if ((firstName !== undefined && lastName !== undefined && mobileNo !== undefined && emailId !== undefined) && (firstName !== '' && lastName !== '' && mobileNo !== '' && emailId !== '') && (firstName !== null && lastName !== null && mobileNo !== null && emailId !== null)) {
                    onClickSubmit();
                } else {
                    if (firstName == undefined || firstName == '' || emailId == null) {
                        setIsFirstNameError(true);
                    }
                    if (lastName == undefined || lastName == '' || emailId == null) {
                        setIsLastNameError(true);
                    }
                    if (mobileNo == undefined || mobileNo == '' || emailId == null) {
                        setIsMobileNoError(true);
                    }
                    if (emailId == undefined || emailId == '' || emailId == null) {
                        setIsEmailIdError(true);
                    }
                }
            } else {
                setErrorMsg('No Internet')
                setErrorPopUp(true)
            }
        } catch (error) {
            catchError(error, 'DeleteAccountPage_1');
        }
    }

    const callApiOnSubmit = async () => {
        try {
            setAPILoading(true);
            const payload = {
                FirstName: firstName,
                LastName: lastName,
                EmailId: emailId,
                MobileNumber: mobileNo
            }
            let response = await CallAPI(endPointName.PermanentDeleteActorDataFromWeb, "post", payload);
            if (response.statusCode == 200) {
                setIsDeleted(true);
                setOpenPopUp(false);
                setFirstName('')
                setLastName('')
                setMobileNo('')
                setEmailId('')
                setSnackBarMessage("Account deleted successfully")
                setOpenSnackBar(true)
                successPopUp()
            } else if (response.statusCode == 404) {
                setAPILoading(false);
                setErrorMsg('Record Not Found')
                setErrorPopUp(true)
            } else {
                setAPILoading(false);
                setErrorMsg('Server Error')
                setErrorPopUp(true)
            }
        } catch (error) {
            catchError(error, "DeleteAccountPage_2")
        }
    }

    const handleCloseDialog = () => {
        try {
            setOpenPopUp(false);
            setFirstName('')
            setLastName('')
            setMobileNo('')
            setEmailId('')
        } catch (error) {
            catchError(error, "DeleteAccountPage_3")
        }
    }

    const handleServerError = () => {
        try {
            setErrorPopUp(false)
            setErrorMsg('')
        } catch (error) {
            catchError(error, "DeleteAccountPage_4")
        }
    }

    const handleCloseSnackBar = (_, reason) => {
        try {
            if (reason === "clickaway") {
                return;
            }
            setOpenSnackBar(false);
        } catch (error) {
            setAPILoading(false);
            catchError(error, "DeleteAccountPage_5");
        }
    }

    const onClickSubmit = () => {
        try {
            Swal.fire({
                title: 'Are you sure you want to delete this account',
                text: '',
                icon: 'question',
                confirmButtonText: 'Yes',
                confirmButtonColor: '#F06C6A',
                cancelButtonText: 'No',
                showCloseButton: true,
                showCancelButton: true,
                customClass: {
                    container: 'swal2-container-zIndex'
                }
            }).then((val) => {
                if (val.isConfirmed) {
                    callApiOnSubmit()
                }
            })
        } catch (error) {
            catchError(error, "DeleteAccountPage_6")
        }
    }

    const successPopUp = () => {
        try {
            Swal.fire({
                title: 'Account Deleted successfully',
                text: '',
                icon: 'success',
                confirmButtonText: 'Yes',
                confirmButtonColor: '#F06C6A',
                customClass: {
                    container: 'swal2-container-zIndex'
                }
            }).then((val) => {
                // if (val.isConfirmed) {
                navigate('/')
                // }
            })
        } catch (error) {
            catchError(error, "DeleteAccountPage_7")
        }
    }
    return (
        <DocumentTitle title={'Delete Account'}>
            <>
                <Container>
                    <Stack spacing={2}>
                        <Grid container spacing={2} >
                            <Grid style={{ width: '100%', }}>
                                <div style={{ padding: 15, display: 'flex' }}>
                                    {openPopUp &&
                                        <Dialog
                                            open={openPopUp}
                                            fullWidth={true}
                                            maxWidth={'md'}
                                            onClose={handleCloseDialog}
                                            aria-labelledby="max-width-dialog-title"
                                        >
                                            <DialogTitle id="max-width-dialog-title" marginBottom={1}>
                                                To delete your account, please provide the following information.
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={handleCloseDialog}
                                                    sx={{
                                                        position: 'absolute',
                                                        right: 8,
                                                        top: 8,
                                                        color: "grey",
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </DialogTitle>
                                            <DialogContent>
                                                <ValidatorForm onSubmit={() => onSubmit()} onError={() => null} >
                                                    <Grid container columns={{ xs: 6, sm: 8, md: 12 }}>

                                                        <Grid item xs={6} md={4} style={{ padding: 5 }}>
                                                            <TextField
                                                                label="First Name*"
                                                                value={firstName}
                                                                onChange={(event) => {
                                                                    setFirstName(event.target.value);
                                                                    setIsFirstNameError(false);
                                                                }}
                                                                inputProps={{ tabIndex: 1 }}
                                                                autoComplete="off"
                                                                fullWidth
                                                                validators={["required"]}
                                                                error={isFirstNameError}
                                                                helpertext={isFirstNameError ? "First Name is required" : ''}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={4} style={{ padding: 5 }}>
                                                            <TextField
                                                                label="Last Name*"
                                                                value={lastName}
                                                                onChange={(event) => {
                                                                    setLastName(event.target.value);
                                                                    setIsLastNameError(false);
                                                                }}
                                                                inputProps={{ tabIndex: 2 }}
                                                                autoComplete="off"
                                                                fullWidth
                                                                validators={["required"]}
                                                                error={isLastNameError}
                                                                helpertext={isLastNameError ? "Last Name is required" : ''}
                                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={4} style={{ padding: 5 }}>
                                                            <TextField
                                                                label="Email Id*"
                                                                value={emailId}
                                                                onChange={(event) => {
                                                                    setEmailId(event.target.value)
                                                                    setIsEmailIdError(false);
                                                                }}
                                                                inputProps={{ tabIndex: 3 }}
                                                                autoComplete="off"
                                                                fullWidth
                                                                validators={["required"]}
                                                                error={isEmailIdError}
                                                                helpertext={isEmailIdError ? "Email Id is required" : ''}
                                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={4} style={{ padding: 5 }}>
                                                            <TextField
                                                                label="Mobile Number*"
                                                                value={mobileNo}
                                                                onChange={(event) => {
                                                                    const regex = /^[0-9\b]+$/;
                                                                    if (event.target.value.length <= 10) {
                                                                        if (event.target.value === "" || regex.test(event.target.value)) {
                                                                            setMobileNo(event.target.value)
                                                                            setIsMobileNoError(false);
                                                                        }
                                                                    }
                                                                }}
                                                                inputProps={{ tabIndex: 4 }}
                                                                autoComplete="off"
                                                                fullWidth
                                                                validators={["required"]}
                                                                error={isMobileNoError}
                                                                helpertext={isMobileNoError ? "Mobile Number is required" : ''}
                                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                </ValidatorForm>

                                            </DialogContent>
                                            <Divider style={{ backgroundColor: '#F7F7F7', }} />
                                            <DialogActions style={{ display: 'flex', justifyContent: isNonMobile == false && 'center' }}>
                                                <div style={{ paddingTop: 0, flexDirection: 'row-reverse', display: 'flex' }}>
                                                    <Button sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#F06C6A' }} variant="contained" type="submit" size='large' tabIndex={13} onClick={() => onSubmit()}>
                                                        <Span sx={{ textTransform: "capitalize" }}>Submit</Span>
                                                    </Button>
                                                </div>
                                                <div style={{ paddingTop: 0, flexDirection: 'row-reverse', display: 'flex' }}>
                                                    <Button sx={{ ":hover": { borderColor: "#ea232a" }, borderColor: '#F06C6A', color: '#F06C6A' }} variant="outlined" type="submit" size='large' tabIndex={13} onClick={() => handleCloseDialog()}>
                                                        <Span sx={{ textTransform: "capitalize" }}>Cancel</Span>
                                                    </Button>
                                                </div>
                                            </DialogActions>
                                        </Dialog>
                                    }

                                    <Grid style={{ width: '100%', }}>
                                        <Grid container direction={"row"} columns={{ xs: 3, sm: 10, md: 12 }}>
                                            <Grid item xs={12} sm={12} md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                                                <img src="images/logo-b.png" alt="Logo" className="mb-1" height={120} style={{ display: 'flex', alignItems: 'center' }} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <div style={{}}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                                                    </div>
                                                    {isDeleted ?
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ marginTop: 0 }}>

                                                                <FcApproval size={40} color={"#696969"} />
                                                            </div>
                                                            <h2>Your account has been permanently deleted.</h2>
                                                        </div>
                                                        :
                                                        <Button sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#F06C6A' }} variant="contained" size="large" style={{ marginTop: 20, marginBottom: 20 }} onClick={() => setOpenPopUp(true)}>Delete Your Account Permanently</Button>
                                                    }
                                                    <h3>Below are the details {isDeleted ? ' that have' : 'that will be'} deleted.</h3>
                                                    <div className="flex-container" style={{ display: 'flex' }}>
                                                        <ul>
                                                            <li>First Name</li>
                                                            <li>Last Name</li>
                                                            <li>DOB</li>
                                                            <li>Gender</li>
                                                            <li>Height</li>
                                                            <li>Weight</li>
                                                            <li>Languages</li>
                                                            <li>Bio</li>
                                                            <li>Skills</li>
                                                            <li>Address 1</li>
                                                            <li>Address 2</li>
                                                            <li>Country</li>
                                                        </ul>
                                                        <ul>
                                                            <li>State</li>
                                                            <li>City</li>
                                                            <li>Zip/Postal Code</li>
                                                            <li>Acting Institute Name</li>
                                                            <li>Dance Institute Name</li>
                                                            <li>Diction Institute Name</li>
                                                            <li>Qualification</li>
                                                            <li>Work Experience</li>
                                                            <li>Category</li>
                                                            <li>Title/Name</li>
                                                            <li>Link</li>
                                                            <li>Social Media details</li>
                                                        </ul>
                                                        <ul>
                                                            <li>Instagram Link</li>
                                                            <li>Facebook Link</li>
                                                            <li>X(Previously Twitter)</li>
                                                            <li>Youtube Channel Link</li>
                                                            <li>IMDb Link</li>
                                                            <li>Other Link</li>
                                                            <li>Cover Photo</li>
                                                            <li>Profile Photo</li>
                                                            <li>Gallery Iamges</li>
                                                            <li>Intro Video</li>
                                                            <li>Monologue Link</li>
                                                            <li>Audition Video</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                {
                                    errorPopUp &&
                                    <ServerErrorModal
                                        popUpFor={errorMsg}
                                        openAlert={errorPopUp}
                                        handleCloseAlert={handleServerError}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
                <footer style={{
                    backgroundColor: "#333",
                    color: '#fff',
                    textAlign: 'center',
                    padding: '10px',
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    display: 'flex'
                }}>
                    <div style={{ flex: 0.8, justifyContent: 'flex-end', display: 'flex' }}>
                        <p>&copy; Yash Raj Films Pvt. Ltd.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href='' onClick={() => navigate("/privacypolicy")}>Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href='' onClick={() => navigate("/termsofservice")}>Terms & Conditions</a></p>
                    </div>
                    <div style={{ flex: 0.5, textAlign: 'center', justifyContent: 'flex-end', display: 'flex' }}>
                        <p style={{ paddingRight: 20 }}>
                            Powered by IFI Techsolutions
                        </p>
                    </div>
                </footer>
                {
                    errorPopUp &&
                    <ServerErrorModal
                        popUpFor={errorMsg}
                        openAlert={errorPopUp}
                        handleCloseAlert={handleServerError}
                    />
                }
                {
                    openSnackBar &&
                    <CustomSnackBar
                        openSnackBar={openSnackBar}
                        handleCloseSnackBar={handleCloseSnackBar}
                        severity="success"
                        message={snackBarMessage}
                    />
                }
            </>

        </DocumentTitle >

    )
}

export default DeleteAccountPage;